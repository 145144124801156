<template>
  <div class="container">
    <PublicHeader :totalName="$t('message').title" />
    <van-list v-model="Notify_loading" :finished="Notify_finished" :loading-text="$t('public').loadingText"
      @load="GetNotifyLog" :immediate-check="false">
      <div class="item" v-for="(item, index) in Notify_List" :key="`notify${index}`">
        <div class="top">
          <div class="name">
            <van-badge dot color="#E90621" v-if="!item.IsRead">
              <img src="@/assets/img/message.png" width="26" style="border-radius: 50%;" />
            </van-badge>
            <img v-if="item.IsRead" src="@/assets/img/message_r.png" width="26" style="border-radius: 50%;" />
            <span>{{ NotifyType | showName(item.NotifyType) }}</span>
          </div>
          <div class="time">{{ item.CreateTime | getLocalTime }} </div>
        </div>
        <div class="txt">{{ translateMessage(item.Content) }} </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      // 通知状态
      NotifyType: [
        {
          Id: 'Account',
          Name: this.$t('message').Account
        },
        {
          Id: 'ETF',
          Name: this.$t('message').ETF
        },
        {
          Id: 'TrustShip',
          Name: this.$t('message').TrustShip
        },
        {
          Id: 'User',
          Name: this.$t('message').User
        },
      ],
      Notify_List: [],
      Notify_loading: false,
      Notify_finished: false,
      Notify_parms: {
        page: 1,
        size: 10,
      },
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.GetNotifyLog()
  },
  methods: {
    //翻译解析数据库文字至i18n识别
    translateMessage(message) {
      return message.replace(/\$t\('(.+?)'\)\.(\w+)/g, (_, key, subkey) => {
        return this.$t(`${key}.${subkey}`);
      });
    },
    // 充币记录
    async GetNotifyLog() {
      const res = await userApi.GetNotifyLog(this.Notify_parms)
      this.Notify_List.push(...res.Data);
      this.Notify_parms.page++
      // 加载状态结束
      this.Notify_loading = false;
      // 数据全部加载完成
      if (this.Notify_List.length >= res.TotalCount) {
        this.Notify_finished = true;
        //更新消息已读
        await userApi.UpNotifyReader()
      }
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-badge--fixed {
  top: 2px;
  right: 2px;
}

.container {
  min-height: 100vh;
  width: 100%;
  padding: 70px 15px 0 15px;
  background: #F6F6F6;

  .item {
    padding: 13px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 10px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .name {
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
        }
      }

      .time {
        font-size: 13px;
        color: #666666;
      }
    }

    .txt {
      font-size: 15px;
      color: #666666;
      line-height: 23px;
    }
  }


}
</style>
